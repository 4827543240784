.login_container {
  margin-bottom: 40px;
  background-image: linear-gradient(to bottom, #f3f3f3, #ffffff);
}

.login_container_header_message {
  width: 80%;
  margin: 0 auto;
  color: #666666;
  text-align: center;
  margin-bottom: 20px;
}

.login_container_header_message p {
  margin: 0;
  font-size: 17px;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.form_row_container {
  display: flex;
  align-self: center;
  flex-direction: row;
}

.form_input_container {
  width: 360px;
  display: flex;
  flex-direction: column;
  margin: auto auto 15px;
}

.form_input {
  color: #464547;
  font-size: 18px;
  text-align: left;
  line-height: 1.86;
  font-style: italic;

  height: 26px;
  outline: none;
  padding: 10px;
  border: solid 1px #cacaca;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.form_label {
  color: #666666;
  font-size: 14px;
  text-align: left;
  line-height: 1.86;
  font-style: italic;
}

.form_buttons_container {
  width: 258px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto auto;
}

.form_login {
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;

  width: 200px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #e28038;
}

.form_password_icon {
  top: -3em;
  left: 88%;
  width: 42px;
  cursor: pointer;
  padding-top: 10px;
  position: relative;
  padding-bottom: 10px;
  background-color: #ffffff;
}

.password_slash {
  left: 90.5%;
  top: -4.9em;
  max-height: 2px;
  position: relative;
  background-color: #464547;
}

.form_password_link {
  color: #464547;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  line-height: 1.29;
  margin: 0 0 20px 0;
}

.form_no_account_container {
  display: flex;
  flex-direction: column;
}

.form_no_account {
  color: #464547;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.13;
  text-align: center;
}

.form_register {
  font-weight: bold;
  text-decoration: underline;
}

.form_check_container {
  z-index: 1;
  width: 360px;
  text-align: center;
  margin: auto auto 40px;
}

.form_check_label {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.terms_text {
  color: #464547;
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
}

.terms_link {
  color: #464547;
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
  text-decoration: underline;
}

.term_check_box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
  border: solid 1px #707070;
  background-color: #ffffff;
}

.term_check_box:focus {
  color: #e28038;
  outline: #e28038;
}

.password_tips {
  width: 390px;
  padding-left: 10px;
  margin: -70px auto auto;
}

.password_list {
  list-style-type: disc;
}

.password_tip {
  color: #666666;
  font-size: 14px;
  text-align: left;
}

.row_form.form_input_container {
  width: auto;
  margin: unset;
}

.row_form .password_slash {
  left: 80.5%;
}

.row_form .form_input {
  width: 155px;
  padding-left: 13px;
}

.row_form .form_password_icon {
  left: 74%;
}

.valid_input_icon {
  left: 90%;
  width: 24px;
  top: -2.4em;
  height: 24px;
  color: #23ab6d;
  position: relative;
}

.password_strength_bar {
  top: -3.2em;
  margin: auto;
  width: 359px;
  position: relative;
}

