@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

.btn {
  font-family: 'Poppins', sans-serif;
}

input:focus {
  outline: solid 1px #e28038 !important;
}
