@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

.btn {
  font-family: 'Poppins', sans-serif;
}

input:focus {
  outline: solid 1px #e28038 !important;
}

.needing_container {
    height: auto;
    background-color: #e9e9e9;
    padding: 0 2em;
}

.needing_text_container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.needing_container_inner {
    max-width: 1400px;
    margin: 0 auto;
}

.needing_title {
    margin-top: 50px;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 0.5;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 15px;
}

.needing_description {
    margin-top: 15px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
    margin-bottom: 40px;
}

.services_container {
    padding-bottom: 80px;
    background-color: #e9e9e9;
}

.service_card {
    width: calc((100% - 60px) / 4 + 0px);
    margin-right: 20px;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
    height: 475px;
    display: flex;
    flex-direction: column;
}

.services_container .flickity-viewport {
    height: 485px !important;
}

@media only screen and (max-width: 1280px) {
    .service_card {
      width: calc((100% - 40px) / 3 + 0px);
    }
  }

@media only screen and (max-width: 1024px) {
    .service_card {
      width: calc((100% - 40px) / 2.5 + 0px);
    }
  }

@media only screen and (max-width: 768px)  {
    .service_card {
        width: calc((100% - 40px) / 2 + 0px);
    }
}

@media only screen and (max-width: 640px)  {
    .service_card {
        width: calc((100% - 20px) / 1 + 0px);
    }
}

.services_container .flickity-prev-next-button.next {
    right: 0 !important;
}

.services_container .flickity-prev-next-button {
    border-radius: unset !important;
}

.services_container .flickity-prev-next-button.previous {
    left: 0 !important;
}

.services_container .flickity-button {
    background: hsla(0,0%,98%,0) !important;
    color: #000000 !important;
}

.card_image, .service_image {
    width: 100%;
}

.service_image {
    object-fit: cover;
}

@media only screen and (max-width: 640px)  {
    .service_image {
        height: 200px;
    }
}

.card_text_container {
    padding: .5em 1em;
    display: flex;
    flex-direction: column;
}

.card_title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #000000;
    margin: 0;
}

.card_description {
    font-size: 15px;
    line-height: 1.38;
    text-align: left;
    color: #333333;
    margin: 10px 0 0 0;
    white-space: normal;
}

.card_button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 1em;
}

.card_button {
    cursor: pointer;
    border-radius: 4px;
    margin: 0 auto;
    background-color: #e28038;
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: .8em 2em;
    text-decoration: none;
}

.card_button:hover {
    background-color: #e97423;
}

.images_content {
    /*cursor: pointer;*/
}

.mobile_only {
    display: none;
}

@media only screen and (max-width: 1380px) {

    .needing_container {
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .needing_title {
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        font-style: unset;
    }

    .needing_description {
        margin-top: 5px;
    }

    .mobile_only {
        display: block;
    }
}

.benefits_container_controller {
  width: 100%;
}

.benefits_container_controller .flickity-page-dots {
  bottom: 10px;
}

.benefits_container_image {
  width: 100%
}

.benefits_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}
.partner_container {
    display: flex;
    flex-direction: column;
}

.partner_title {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    line-height: 0.83;
    text-align: center;
    margin-bottom: 20px;
}

.partner_description {
    margin-top: 0;
    color: #051a31;
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
}

.partners_content {
    margin-top: 40px;
    margin-bottom: 80px;
}

.partners_text_container {
    text-align: center;
}

.partner_title {
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 0.5;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 20px;
}

.partner_description {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #051a31;
}

.partner_images_container {
    overflow: hidden;
    margin-bottom: 80px;
    place-content: center;
}

.partner_image {
    height: 140px;
    margin: 4em;
}

.partner_name {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

@media only screen and (max-width: 770px) {
    .partner_title {
        padding-top: 1.5em;
        font-style: unset;
        font-weight: bold;
        font-size: 20px;
        color: #000000;
    }

    .partner_description {
        width: 16em;
        margin: auto;
    }

    .partner_name {
        text-align: center;
        line-height: 20px;
        font-size: 18px;
        font-style: unset;
        margin-top: -50px;
    }

    .partner_image {
        margin: 2.5em 1em 10px;
    }

    .partner_image img {
        height: 112px;
    }

    .partners_container {
        margin: -5em;
    }

    .partner_images_container {
        margin-bottom: 40px;
    }

    .partner_images_container .flickity-slider {
        left: 0 !important;
    }
}

.header_container {
    display: flex;
    flex-direction: column;
}

.header_top_line {
    height: 7px;
    background-color: #e28038;
}

.header_content {
    margin: auto;
    display: flex;
    flex-direction: row;
    margin: 30px auto;
    align-items: center;
}

.header_logo {
    height: 36px;
    width: 190px;
    margin-right: 20px;
}

.menu_content {
    display: flex;
    text-align: center;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
}

.menu_link_content {
    display: flex;
    flex-direction: column;
}

.menu_link {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 30px;
    text-transform: uppercase;
}

.menu_icon {
    font-size: 12px;
    align-self: center;
    margin-right: 30px;
}

.menu_dropdown {
    display: none;
    padding-left: 0;
    margin-top: 38px;
    position: absolute;
    list-style-type: none;
    background-color: #ffffff;
    border: solid 1px #707070;
}

.menu_list_item {
    min-width: 125px;
    cursor: pointer;
    color: #515151;
    font-size: 15px;
    text-align: left;
    text-transform: uppercase;
    padding: 10px 10px 10px 15px;
}

.menu_list_item:hover {
    background-color: #e8e8e8;
}

.menu_link_content:hover .menu_dropdown {
    display: flex;
    flex-direction: column;
}

.account_items {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.account_divisor {
    width: 2px;
    height: 15px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #e28038;
}

.account_link {
    color: #e28038;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
}

.mobile_header {
    display: none;
}

.account_user_item {
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
}

.account_user_image {
  margin-right: 15px;
}

.account_user_content {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.mobile.account_user_item {
  width: 100%;
  align-self: center;
  flex-direction: column;
}

.account_user_content_m {
  flex-direction: row;
}

.mobile.account_user_name {
  margin-right: 10px;
  margin-bottom: revert;
}

.account_user_name {
  color: #e28038;
  font-size: 16px;
  text-align: left;
  margin-bottom: 0;
  line-height: 1.56;
}

.account_user_drop_icon {
  margin-top: 0;
}

.account_user_menu {
  top: 8em;
  display: flex;
  margin-left: -3em;
  position: absolute;
  flex-direction: column;
  background-color: #ffffff;
  border: solid 1px #e28038;
  border-bottom: none;
}

.account_user_menu_item {
  color: #e28038;
  cursor: pointer;
  font-size: 15px;
  text-align: left;
  text-transform: uppercase;

  margin-top: 0;
  margin-bottom: 0;
  padding: 8px 30px 8px 20px;
  border-bottom: solid 1px #e28038;
}

.account_user_menu_item:hover {
  background-color: #e3e3e3;
}

.account_link_mobile {
  width: 100%;
  padding-top: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.account_user_menu_mobile {
  width: 100%;
  display: none;
  position: relative;
  margin-bottom: 5px;
  text-decoration: none;
  flex-direction: column;
  background-color: #ffffff;
  border: solid 1px #e28038;
  border-bottom: none;
}

.account_user_menu_mobile .account_user_menu_item {
  text-align: center;
}

@media only screen and (max-width: 1300px) {

    .account_user_menu {
      display: none;
    }

    .account_user_menu_mobile {
      display: flex;
    }

    .header_content {
        display: none;
    }

    .header_top_line {
        display: none;
    }

    .logo_mobile {
        width: 130px;
    }

    .mobile_header {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .mobile_header_logos {
        margin-left: 20px;
    }

    .mobile_header_menu {
        top: 1.5em;
        right: 20px;
        position: absolute;
    }

    .topnav_itens {
        width: 100vw;
        display: flex;
        position: relative;
        -webkit-transform-origin: top;
                transform-origin: top;
        flex-direction: column;
        transition: max-height,-webkit-transform 0.3s ease;
        transition: max-height,transform 0.3s ease;
        transition: max-height,transform 0.3s ease,-webkit-transform 0.3s ease;
    }

    .mobile_menu_item {
        width: 100%;
        color: #000000;
        font-size: 18px;
        margin: 6px auto;
        padding-top: 10px;
        text-align: center;
        padding-bottom: 10px;
        text-decoration: none;
    }

    .mobile_menu_item:hover {
        background-color: #e8e8e8;
    }

    .mobile_submenu_icon {
        margin-left: 5px;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    }

    .submenu_content {
        top: 1000em;
        display: flex;
        padding-left: 0;
        list-style: none;
        margin: 10px 0 0;
        position: absolute;
        -webkit-transform: scaleY(0);
                transform: scaleY(0);
        padding-bottom: 10px;
        -webkit-transform-origin: top;
                transform-origin: top;
        flex-direction: column;
        background-color: #e8e8e8;
        border-top: solid 1px #000000;
        transition: -webkit-transform 0.5s ease;
        transition: transform 0.5s ease;
        transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

    .open {
        top: 0;
        display: flex;
        position: -webkit-sticky;
        position: sticky;
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }

    .submenu_content .mobile_menu_item {
        margin-top: 12px;
    }

    .submenu_content .mobile_menu_item:hover {
        background-color: #c8c8c8;
    }

    .hamburger_menu {
        z-index: 1;
        top: 1.5em;
        right: 20px;
        width: 25px;
        height: 25px;
        position: absolute;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .hamburger_menu span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #000000;
        border-radius: 10px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    .hamburger_menu span:nth-child(1) {
        top: 0;
        -webkit-transform-origin: left center;
        transform-origin: left center;
    }

    .hamburger_menu span:nth-child(2) {
        top: 8px;
        -webkit-transform-origin: left center;
        transform-origin: left center;
    }

    .hamburger_menu span:nth-child(3) {
        top: 16px;
        -webkit-transform-origin: left center;
        transform-origin: left center;
    }

    .hamburger_menu.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0;
        left: 0;
    }

    .hamburger_menu.open span:nth-child(2) {
        width: 0;
        opacity: 0;
    }

    .hamburger_menu.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 17px;
        left: 0;
    }

}

.footer_content {
    text-align: center;
    margin-bottom: 35px;
    border-top: solid 1px #333333;
}

.footer_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #3f1c57;
}

.footer_row {
    display: flex;
    margin-top: 35px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer_logo {
    position: absolute;
    display: block;
    left: 10em;
}

.footer_logo_mobile {
    display: none;
}

.powered_by {
    left: 16.3em;
    color: #000000;
    font-size: 14px;
    text-align: left;
    position: absolute;
    margin-top: -1.4em;
}

@media only screen and (max-width: 770px) {
    .powered_by {
        display: none;
    }

    .footer_text {
        font-size: 14px;
        margin-left: 10px;
    }

    .footer_logo {
        display: none;
    }

    .footer_logo_mobile {
        display: block;
    }
}

.family_benefits_container {
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #e28038;
}

.f_benefits_content {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    place-content: center;
}

.hearts_icon {
    height: 160px;
}

.f_benefits_text_container {
    display: flex;
    color: #ffffff;
    margin-left: 50px;
    flex-direction: column;
}

.f_benefits_primary_text {
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    line-height: 1.44;
    margin: 0 0 15px 0;
}

.f_benefits_secondary_text {
    margin: 0;
    font-size: 18px;
    text-align: left;
    line-height: 1.44;
}

@media only screen and (max-width: 770px) {
    .f_benefits_content {
        flex-direction: column;
    }

    .f_benefits_text_container {
        margin: auto;
    }

    .hearts_icon {
        display: none;
    }
}

.instagram_feed_container {
  background-color: #f3f3f3;
}

.instagram_profile_content {
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  place-content: center;
}

.instagram_text_container {
  display: flex;
  margin-left: 40px;
  flex-direction: column;
}

.instagram_message_content {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
}

.instagram_text_title {
  width: 176px;
  margin-top: 0;
  color: #000000;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  line-height: 1.17;
  margin-bottom: 10px;
}

.instagram_text_description {
  width: 270px;
  margin-top: 0;
  color: #051a31;
  font-size: 18px;
  text-align: left;
  line-height: 1.33;
}

.instagram_media_content {
  display: flex;
  max-width: 769px;
  margin-top: 40px;
  max-height: 275px;
  margin-left: 32px;
  flex-direction: row;
  margin-bottom: 40px;
}

.instagram_post_card {
  color: #051a31;
  text-align: center;
}

.instagram_post_image {
  height: 250px;
  margin-left: 8px;
  margin-right: 8px;
}

@media only screen and (max-width: 1380px) {
  .instagram_profile_content {
    flex-direction: column;
  }

  .instagram_media_content {
    margin-top: auto;
  }
}

@media only screen and (max-width: 980px) {
  .instagram_message_content {
    align-items: center;
    flex-direction: column;
  }
  .instagram_media_content {
    overflow: scroll;
    max-width: 300px;
    margin-left: 0;
  }
}

.videos_container {
    display: flex;
    flex-direction: column;
}

.videos_title {
    color: #333333;
    font-size: 18px;
    margin-top: 15px;
    font-weight: bold;
    line-height: 1.22;
    text-align: center;
    margin-bottom: 10px;
}

.videos_description {
    margin-top: 0;
    color: #333333;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
}

.video_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.video_title {
    height: 42px;
    color: #000000;
    line-height: 1;
    font-size: 18px;
    text-align: center;
}

.customPlay {
    align-self: center;
}

.video_thumb {
    min-width: 260px;
    width: 100%;
    height: 160px;
    background-size: cover;
    background-color: #000000;
    background-position: center;
    box-shadow:inset 0 0 0 2000px rgba(226, 128, 56, 0.5);
}

.thumb_play_icon {
    top: 3.5em;
    left: 6.8em;
    position: relative;
}

.videos_carrousel_button {
    border: none;
    outline: none;
    background-color: #ffffff;
}

@media only screen and (min-width: 1000px) {
  .videos_title {
    font-size: 24px;
  }

  .videos_description {
    font-size: 18px;
  }

  .video_container {
    width: auto;
  }

  .video_title {
    font-size: 20px;
    min-width: 330px;
  }

  .video_thumb {
    min-width: 285px;
  }
}

.login_container {
  margin-bottom: 40px;
  background-image: linear-gradient(to bottom, #f3f3f3, #ffffff);
}

.login_container_header_message {
  width: 80%;
  margin: 0 auto;
  color: #666666;
  text-align: center;
  margin-bottom: 20px;
}

.login_container_header_message p {
  margin: 0;
  font-size: 17px;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.form_row_container {
  display: flex;
  align-self: center;
  flex-direction: row;
}

.form_input_container {
  width: 360px;
  display: flex;
  flex-direction: column;
  margin: auto auto 15px;
}

.form_input {
  color: #464547;
  font-size: 18px;
  text-align: left;
  line-height: 1.86;
  font-style: italic;

  height: 26px;
  outline: none;
  padding: 10px;
  border: solid 1px #cacaca;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.form_label {
  color: #666666;
  font-size: 14px;
  text-align: left;
  line-height: 1.86;
  font-style: italic;
}

.form_buttons_container {
  width: 258px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto auto;
}

.form_login {
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;

  width: 200px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #e28038;
}

.form_password_icon {
  top: -3em;
  left: 88%;
  width: 42px;
  cursor: pointer;
  padding-top: 10px;
  position: relative;
  padding-bottom: 10px;
  background-color: #ffffff;
}

.password_slash {
  left: 90.5%;
  top: -4.9em;
  max-height: 2px;
  position: relative;
  background-color: #464547;
}

.form_password_link {
  color: #464547;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  line-height: 1.29;
  margin: 0 0 20px 0;
}

.form_no_account_container {
  display: flex;
  flex-direction: column;
}

.form_no_account {
  color: #464547;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.13;
  text-align: center;
}

.form_register {
  font-weight: bold;
  text-decoration: underline;
}

.form_check_container {
  z-index: 1;
  width: 360px;
  text-align: center;
  margin: auto auto 40px;
}

.form_check_label {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.terms_text {
  color: #464547;
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
}

.terms_link {
  color: #464547;
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
  text-decoration: underline;
}

.term_check_box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;
  border: solid 1px #707070;
  background-color: #ffffff;
}

.term_check_box:focus {
  color: #e28038;
  outline: #e28038;
}

.password_tips {
  width: 390px;
  padding-left: 10px;
  margin: -70px auto auto;
}

.password_list {
  list-style-type: disc;
}

.password_tip {
  color: #666666;
  font-size: 14px;
  text-align: left;
}

.row_form.form_input_container {
  width: auto;
  margin: unset;
}

.row_form .password_slash {
  left: 80.5%;
}

.row_form .form_input {
  width: 155px;
  padding-left: 13px;
}

.row_form .form_password_icon {
  left: 74%;
}

.valid_input_icon {
  left: 90%;
  width: 24px;
  top: -2.4em;
  height: 24px;
  color: #23ab6d;
  position: relative;
}

.password_strength_bar {
  top: -3.2em;
  margin: auto;
  width: 359px;
  position: relative;
}


.router_header_container {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: row;
  margin: 0 auto;
  padding: 40px 0;
  align-items: center;
}

.router_header_back_container {
  left: 4%;
  display: flex;
  cursor: pointer;
  line-height: 0.83;
  position: absolute;
  align-items: center;
  flex-direction: row;
}

.router_header_content {
  display: flex;
  line-height: 0.83;
  text-align: center;
  flex-direction: column;
}

.router_header_title {
  color: #000000;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  line-height: 0.83;
  text-align: center;
}

.router_header_info_page {
  color: #666666;
  font-size: 14px;
  margin: 10px 0 0 0;
  line-height: 1.86;
  text-align: center;
}

@media only screen and (max-width: 990px) {
  .router_header_back_container {
    display: none;
  }
}

.detail_container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.detail_banner_container {
    height: 250px;
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.detail_banner_title_container {
    width: 290px;
    height: 120px;
    align-self: center;
    margin-left: 350px;
}

.detail_banner_title {
    line-height: 2;
    color: #ffffff;
    font-size: 30px;
    margin-top: 1.4em;
    font-weight: bold;
    margin-left: 2.8em;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.detail_container .cards_container {
    display: flex;
    margin-top: 60px;
    flex-direction: column;
}

.detail_title_container {
    display: flex;
    align-self: center;
    flex-direction: row;
}

.detail_back_button {
    left: 20em;
    color: #000000;
    font-size: 14px;
    line-height: 1.86;
    text-align: center;
    align-self: center;
    position: absolute;
}

.detail_back_icon {
    margin-right: 4px;
}

.detail_cards_question {
    margin-top: 0;
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    line-height: 0.83;
    text-align: center;
    margin-bottom: 10px;
}

.details_cards_description {
    color: #000000;
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.44;
    text-align: center;
}

.cards_content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    justify-content: center;
}

.detail_card_image_container .card_image {
    height: 293px;
}

@media only screen and (min-width: 1000px) {
  .detail_banner_title_container {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
    .detail_banner_container {
      height: auto;
      background-position: center;
    }

    .detail_banner_title_container {
        background-image: unset !important;
    }

    .detail_banner_title {
      display: none;
    }
}

@media only screen and (max-width: 1350px) {
    .detail_title_container {
        flex-direction: column;
    }

    .detail_back_button {
        position: unset;
        margin-bottom: 20px;
    }
}

.detail_card {
  width: 340px;
  margin: 10px 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.card_highlight {
  height: 30px;
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #e28038;
}

.detail_card_image_container {
  width: 100%;
  height: 260px;
  text-align: center;
}

.product_card_image {
  width: 340px;
  height: 240px;
}

.card_image_partner {
  float: right;
  max-width: 60px;
  max-height: 60px;
  margin-top: -5em;
  margin-right: 1em;
  position: relative;
}

.product_card_text_container {
  display: flex;
  padding-left: 15px;
  flex-direction: column;
}

.product_card_title {
  width: 300px;
  margin-top: 0;
  color: #000000;
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 8px;
}

.product_card_description {
  width: 320px;
  margin-top: 0;
  height: 100px;
  overflow: auto;
  color: #333333;
  font-size: 16px;
  max-width: 390px;
  text-align: left;
  margin-bottom: 0;
  line-height: 1.38;
  max-height: 100px;
}

.card_link_container {
  text-align: center;
  margin-bottom: 20px;
}

.card_link_button {
  height: 40px;
  width: 200px;
  display: inline-block;
  border-radius: 4px;
  background-color: #e28038;
}

.card_link_button_text {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  line-height: 40px;
  text-align: center;
}

.product_card_complement {
  width: 340px;
  color: #2e5d8f;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 20px;
}

@media only screen and (max-width: 770px) {
  .detail_card {
    width: 300px;
    min-height: 420px;
    margin: 10px auto;
  }

  .card_highlight {
    height: 25px;
    width: 300px;
    margin: auto;
    line-height: 25px;
  }

  .product_card_image {
    width: 300px;
    height: 200px;
  }

  .detail_card_image_container {
    height: 219px;
  }

  .detail_card .card_image {
    width: 320px;
    height: unset;
  }

  .card_image_partner {
    left: 0;
    top: 1em;
    width: auto;
    position: relative;
  }

  .product_card_title {
    width: 250px;
    height: auto;
    font-size: 16px;
    line-height: 15px;
  }

  .product_card_description {
    width: 270px;
    height: 100px;
  }

  .product_card_complement {
    width: 270px;
    color: #2e5d8f;
    font-size: 16px;
    margin-top: 20px;
  }
}

.subcategoryTitleSection {
  margin-bottom: 35px;
}

.subCategoryTitle {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.subCategoryCardSection {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 40px;
  justify-content: center;
}

@media only screen and (max-width: 980px) {
  .subCategoryCardSection {
    flex-direction: column;
  }
}

.product-details {
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  position: fixed;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.product-details-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 5rem 1rem;
}

.product-details-heading {
  display: flex;
  flex-direction: row;
  padding: 20px; 
  justify-content: space-between;
  align-items: center;
}

.product-details-content {
  margin: auto;
  width: 100%;
  max-width: 42rem;
  z-index: 1000;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.26);
}

.product-details-close-icon {
  cursor: pointer;
  flex-grow: 0;
  justify-self: center;
}

.product-details-title {
  color: #515151;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  text-align: center;
  flex-grow: 1;
}

.product-details-attributes {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #dadada;
}

.product-details-attribute {
  margin: 0;
  color: #515151;
  font-size: 20px;
  cursor: pointer;
  font-weight: 900;
  text-align: center;
  padding: 15px;
}

.product-details-attribute-name {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
}

.product-details-attribute-value {
  color: #000000;
  overflow: auto;
  font-size: 16px;
  text-align: left;
  padding: 30px 30px 0 30px;
  min-height: 25vh;
  max-height: 25vh;
}

.product-details-footer {
  text-align: center;
  padding: 20px;
  
}

.product-details-complement {
  color: #2e5d8f;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
}

.product-details-complement-bold {
  font-size: 20px;
  font-weight: bold;
}

.product-details-button-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.product-details-exclusive {
  color: #bd1120;
  font-size: 14px;
  margin-bottom: 10px;
}

.product-details-button {
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  line-height: 60px;

  border: none;
  height: 60px;
  width: 330px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
  display: inline-block;
  background-color: #e28038;
}

.product-details-footer-info {
  display: flex;
  flex-direction: column;
  color: #000000;
  font-size: 16px; 
  margin-bottom: 0;
  margin-top: 5px;
}

.product-details-footer-info-redirect {
  font-style: italic;
}

.product-details-footer-info-entrar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
}

.product-details-footer-info-entrar a {
  text-decoration: underline;
  margin-left: 5px;
  display: inline-block;     
  position: relative;    
  z-index: 1;     
  padding: 2em;     
  margin: -2em; 
}

@media only screen and (max-width: 980px) {
  .product-details-inner {
    padding: 0;
  }
  .product-details-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .product-details-footer {
    margin-top: auto;
  }

  .product-details-attribute-value {
    color: #000000;
    overflow: auto;
    font-size: 16px;
    text-align: left;
    padding: 20px 20px 0 20px;
    max-height: 35vh;
  }
}

.educador_full_container {
  background-image: linear-gradient(to bottom, #f3f3f3, #ffffff);
}

.educador_info_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  place-content: center;
  margin: auto auto 180px;
}

.educador_info_image {
  width: 307px;
  height: 467px;
  margin: 33px 35px 0 0;
}

.educador_info_text {
  color: #000000;
  font-size: 18px;
  max-width: 800px;
  text-align: left;
  line-height: 1.44;
  margin: 20px auto auto;
}

.educador_info_text a {
  color: #333333;
  font-weight: 600;
  text-decoration: none;
}

.educador_info_text li {
  margin-bottom: 10px;
}

.educador_title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.educador_text_container {
  margin-bottom: 40px;
}

.educador_description {
  margin-top: 0;
  color: #051a31;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
}

.educador_info_text a {
  overflow-wrap: anywhere;
}

.educador_links_container {
  display: flex;
  flex-direction: row;
}

.educador_link_card {
  display: flex;
  color: #000000;
  max-width: 200px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  text-decoration: none;
  flex-direction: column;
}

.educador_link_image {
  width: 120px;
}

.educador_links_container ul {
  width: 200px;
}

.educador_links_container li {
  color: #000000;
  font-size: 14px;
  text-align: left;
  line-height: 1.14;
  margin-bottom: 15px;
}

@media only screen and (max-width: 980px) {
  .educador_links_container {
    flex-direction: column;
  }

  .educador_links_container {
    flex-direction: column;
  }

  .educador_link_card {
    margin: auto;
  }
}

@media only screen and (max-width: 1380px) {
  .educador_info_container {
    margin-bottom: 40px;
    flex-direction: column;
  }

  .educador_info_image {
    width: 300px;
    height: 300px;
    margin: unset;
  }

  .educador_info_text {
    padding: 20px;
  }

  .educador_text_container {
    margin-bottom: 20px;
  }
}

.question_card {
  width: 800px;
  display: flex;
  padding: 10px 35px;
  flex-direction: column;
  margin: 25px auto auto;
  border: solid 1px #cacaca;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.question_card_header {
  display: flex;
  cursor: pointer;
  flex-direction: row;

  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.44;
  text-align: left;
  color: #464547;
}

.question_card_title {
  width: 100%;
}

.question_card_icon {
  float: right;
  align-self: center;
  position: relative;
}

.question_card_content {
  font-size: 16px;
  line-height: 1.63;
  text-align: left;
  color: #000000;
}

@media only screen and (max-width: 980px) {
  .question_card {
    width: 80%;
    padding: 5px 20px;
  }
}

.requireSub_container {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
}

.requireSub_Modal {
  margin: 40px auto auto;
  display: flex;
  padding-left: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px;
  padding-right: 15px;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

.requireSub_modal_close {
  top: .5em;
  left: 11.5em;
  cursor: pointer;
  position: relative;
}

.requireSub_modal_title {
  width: 400px;
  color: #515151;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.14;
  text-align: center;
}

.requireSub_modal_icon {
  width: 160px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.requireSub_modal_text {
  width: 400px;
  color: #515151;
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  margin-bottom: 20px;
}

.requireSub_modal_buttons {
  display: flex;
  flex-direction: column;
}

.requireSub_modal_button {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 62px;
  text-align: center;

  height: 62px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #e28038;
}

.existingAccount_item {
  color: #464547;
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  margin-bottom: 40px;
}

.existingAccount_item p {
  margin-bottom: 5px;
}

.existingAccount_item a {
  color: #464547;
  margin-top: 5px;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (max-width: 990px) {
  .requireSub_modal_title {
    width: 300px;
  }
  .requireSub_modal_text {
    width: 300px;
  }
  .requireSub_modal_close {
    left: 9em;
  }
}

