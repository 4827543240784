.requireSub_container {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
}

.requireSub_Modal {
  margin: 40px auto auto;
  display: flex;
  padding-left: 15px;
  width: fit-content;
  border-radius: 8px;
  padding-right: 15px;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

.requireSub_modal_close {
  top: .5em;
  left: 11.5em;
  cursor: pointer;
  position: relative;
}

.requireSub_modal_title {
  width: 400px;
  color: #515151;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.14;
  text-align: center;
}

.requireSub_modal_icon {
  width: 160px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.requireSub_modal_text {
  width: 400px;
  color: #515151;
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  margin-bottom: 20px;
}

.requireSub_modal_buttons {
  display: flex;
  flex-direction: column;
}

.requireSub_modal_button {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  line-height: 62px;
  text-align: center;

  height: 62px;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #e28038;
}

.existingAccount_item {
  color: #464547;
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  margin-bottom: 40px;
}

.existingAccount_item p {
  margin-bottom: 5px;
}

.existingAccount_item a {
  color: #464547;
  margin-top: 5px;
  font-weight: 700;
  text-decoration: underline;
}

@media only screen and (max-width: 990px) {
  .requireSub_modal_title {
    width: 300px;
  }
  .requireSub_modal_text {
    width: 300px;
  }
  .requireSub_modal_close {
    left: 9em;
  }
}
